import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
    Button,
    TextField,
    Grid,
    Paper,
    Typography,
    Link,
    Toolbar,
} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ApiManager from "../utils/ApiManager";
import qs from "qs";
import LoadingAnimation from "../components/LoadingAnimation/LoadingAnimation";

const useStyles = makeStyles(theme => ({
    toolbar: {
        background: theme.palette.primary.main
    },
    loginForm:
    {
        justifyContent: "center",
        minHeight: "90vh"
    },
    buttonBlock: {
        width: "100%"
    },
    loginBackground:
    {
        justifyContent: "center",
        minHeight: "30vh",
        padding: "50px",
        placeSelf: "center",
        "@media (max-width: 992px)": {
            maxWidth: "85%",
        },
        "@media (min-width: 1000px)": {
            maxWidth: "75%",
        }
    },
    loginTitle: {
        marginBottom: "2vh"
    },
    loginBody: {
        backgroundImage: 'url("/images/bg_cooking_min.jpg")',
        backgroundSize: "cover"
    },
    inviteCodeTextField: {
        boxShadow: "0px 0px 10px 0px #ffd700"
    }
}));

export default function Recipe(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [password, setPassword] = React.useState("")
    const [repeatedPassword, setRepeatedPassword] = React.useState("")
    const [passwordErrorText, setPasswordErrorText] = React.useState("")
    const [inviteCode, setInviteCode] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [username, setUsername] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const history = useHistory();


    const handleSubmitRegister = async () => {
        try {

            if (!validatePassword(password, repeatedPassword)) return

            setLoading(true)
            await ApiManager.register(email, username, password, inviteCode);
            history.push("/login");
        } catch (error) {
            setLoading(false)
            console.error(error)
        }
    }

    const validatePassword = (password1, password2) => {
        if (password1 !== password2) {
            setPasswordErrorText("The passwords don't match")
            return false
        }

        if (!isValidPassword(password1)) {
            setPasswordErrorText("Your password must be at least 8 characters long and contain at least 1 digit and 1 special character")
            return false
        }

        return true
    }

    // Password must be at least 8 characters long and contain
    // 1 digit
    // 1 special character
    const isValidPassword = (value) => {

        if ((value.length > 7) &&
            /\d/.test(value) &&
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value)) {
            return true
        }

        return false;
    }

    React.useEffect(() => {
        if (qs.parse(window.location.search, { ignoreQueryPrefix: true }).inviteCode) {
            setInviteCode(qs.parse(window.location.search, { ignoreQueryPrefix: true }).inviteCode.toString());
        } else {
        }
    }, [])

    return (
        <div>
            <Paper elevation={3}> <Toolbar className={classes.toolbar}></Toolbar></Paper>
            <Grid className={classes.loginBody} container spacing={0} justify="center" direction="row">
                {loading ? <LoadingAnimation /> : null}
                <Grid item>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        spacing={2}
                        className={classes.loginForm}
                    >
                        <Paper
                            variant="elevation"
                            elevation={2}
                            className={classes.loginBackground}
                        >
                            <Grid className={classes.loginTitle} item>
                                <Typography component="h1" variant="h5">
                                    Register your account
                                </Typography>
                            </Grid>
                            <Grid item>
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmitRegister();
                                }}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <TextField
                                                className={classes.inviteCodeTextField}
                                                type="text"
                                                placeholder="Golden Ticket Code"
                                                fullWidth
                                                name="inviteCode"
                                                variant="outlined"
                                                value={inviteCode}
                                                onChange={(event) =>
                                                    setInviteCode(event.target.value)
                                                }
                                                required
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                type="text"
                                                placeholder="Your cool username"
                                                fullWidth
                                                name="username"
                                                variant="outlined"
                                                value={username}
                                                onChange={(event) =>
                                                    setUsername(event.target.value)
                                                }
                                                required
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                type="email"
                                                placeholder="Email"
                                                fullWidth
                                                name="email"
                                                variant="outlined"
                                                value={email}
                                                onChange={(event) =>
                                                    setEmail(event.target.value)
                                                }
                                                required
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                type="password"
                                                placeholder="Password"
                                                fullWidth
                                                name="password"
                                                variant="outlined"
                                                value={password}
                                                onChange={(event) => setPassword(event.target.value)}
                                                required
                                            />
                                            <TextField
                                                type="password"
                                                placeholder="Repeat password"
                                                fullWidth
                                                name="repeatedPassword"
                                                variant="outlined"
                                                value={repeatedPassword}
                                                onChange={(event) => setRepeatedPassword(event.target.value)}
                                                required
                                                helperText={passwordErrorText}
                                                error={passwordErrorText.length > 0}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                className={classes.buttonBlock}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
