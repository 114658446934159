import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import TimerIcon from '@material-ui/icons/Timer';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ApiManager from "../utils/ApiManager";
import LoadingAnimation from '../components/LoadingAnimation/LoadingAnimation'

import ReactHtmlParser from 'react-html-parser';

import {
    useLocation
} from "react-router-dom";
import ConfirmationDialog from "../components/ConfirmationDialog/ConfirmationDialog";
const queryString = require('query-string');

export default function Recipe(props) {

    const theme = useTheme();
    const useStyles = makeStyles({
        root: {
            display: "flex"
        },
        recipeTitle: {
            fontFamily: "Lobster",
            fontWeight: "lighter",
            marginBottom: "35px"
        },
        recipeText: {
            width: "90%",
            borderStyle: "dashed",
            padding: "4%"
        },
        recipeImageSmall: {
            width: "60%",
            maxHeight: "50%"
        },
        recipeImageLarge: {
            width: "85%",
            maxHeight: "50%"
        },
        deleteButton: {
            background: theme.palette.error.main,
            "&:hover": {
                background: theme.palette.error.main
            },
            marginTop: "20px",
            color: theme.palette.offWhite.main
        },
        editButton: {
            background: "#416970",
            height: "50px",
            marginLeft: "20px",
            marginBottom: "0px"
            // marginTop: "20px"
        },
        recipeHeader: {
            display: "flex",
            alignItems: "baseline"
        },
        cookingTimeWrapper: {
            display: "flex",
            alignItems: "center",
            marginBottom: "5px",
            marginTop: "15px"
        }
    });

    const classes = useStyles();

    const [recipe, setRecipe] = React.useState([])
    const [recipeUUID, setRecipeUUID] = React.useState(null)
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const location = useLocation();
    const history = useHistory();

    const handleLoadRecipe = async () => {
        try {
            setLoading(true);
            let loadedRecipe;
            loadedRecipe = await ApiManager.loadRecipe(recipeUUID);
            setRecipe(loadedRecipe.data)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error)
        }
    }

    const handleDeleteRecipe = async () => {

        setLoading(true);
        await ApiManager.deleteRecipe(recipeUUID);
        history.push("/app/recipe-book")

        return;
    }

    const handleDialogClose = () => {
        setDeleteDialogOpen(false)
    }

    React.useEffect(() => {
        if (recipeUUID) {
            handleLoadRecipe()
        }
    }, [recipeUUID])

    React.useEffect(() => {
        const parsed = queryString.parse(location.search);
        setRecipeUUID(parsed.recipeUUID)
    }, [location])

    return (
        <div>
            {loading ? <LoadingAnimation /> : null}
            <h1 className={classes.recipeTitle}>{recipe.name}</h1>
            <img className={`${matches ? classes.recipeImageSmall : classes.recipeImageLarge}`}
                src={recipe.recipeImages && recipe.recipeImages.length > 0 ? recipe.recipeImages[0].image_src : ""} />
            <div className={classes.recipeHeader}> <h2>{"RECIPE"}</h2>
                <Button
                    variant="contained"
                    className={classes.editButton}
                    onClick={() => { history.push("/app/edit-recipe?recipe=" + recipe.uuid) }}
                ><EditIcon style={{ color: theme.palette.offWhite.main }} />
                </Button>
            </div>
            <div className={classes.cookingTimeWrapper}><TimerIcon />{"Cooking time: "} {recipe.cooking_time ? recipe.cooking_time : "--"} {"minutes"}</div>
            <div className={classes.recipeText}> {ReactHtmlParser(recipe.description)} </div>
            <Button
                variant="contained"
                className={classes.deleteButton}
                onClick={() => setDeleteDialogOpen(true)}
            >
                {"Delete"}
            </Button>
            <ConfirmationDialog isOpen={deleteDialogOpen} onClose={handleDialogClose} onConfirm={handleDeleteRecipe} />
        </div>
    );
}
