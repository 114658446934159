import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
    Button,
    Toolbar,
    Paper
} from "@material-ui/core";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const useStyles = makeStyles(theme => ({
    center: {
        textAlign: "center"
    },
    toolbar: {
        background: theme.palette.primary.main,
        marginBottom: "60px"
    },
    h1: {
        color: "#416970",
        textDecoration: "underline",
        textAlign: "center",
        fontFamily: "Lobster",
        fontSize: "60px"
    },
    topMargin: {
        marginTop: "50px"
    },
    centerWrapper: {
        display: "flex",
        marginBottom: "15px"
    },
    loginButton: {
        margin: "auto",
        // background: "#416970",
        color: "white"
    },
    seperationText: {
        margin: "auto",
        fontSize: "20px",
        fontWeight: "bold"
    },
    loginIcon: {
        marginLeft: "10px"
    }

}));

function WelcomePage(props) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    return (
        <>
            <Paper elevation={3}> <Toolbar className={classes.toolbar}></Toolbar></Paper>
            <h1 className={classes.h1} >{"RecipeFox"}</h1>

            <h4 className={classes.center}>{"The recipe home for you and your friends 🍝"}</h4>

            <div className={`${classes.centerWrapper} ${classes.topMargin}`}>
                <Button color="primary" className={classes.loginButton} variant="contained" href="login">
                    {"Login"} <VpnKeyIcon className={classes.loginIcon} />
                </Button>
            </div>
            <div className={classes.centerWrapper}>
                <span className={classes.seperationText}>{"OR"}</span>
            </div>
            <div className={classes.centerWrapper}>
                <Button color="primary" className={classes.loginButton} variant="contained" href="register">
                    {"Register"} <PersonAddIcon className={classes.loginIcon} />
                </Button>
            </div>

        </>
    );
}

export default WelcomePage;
