import React from 'react';
import ResponsiveDrawer from './components/ResponsiveDrawer.jsx'
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import RecipeBook from './pages/RecipeBook';
import RecipeEditor from './pages/RecipeEditor/RecipeEditor'
import Friends from './pages/Friends'
import WelcomePage from './pages/WelcomePage'
import Recipe from './pages/Recipe'
import Login from './pages/Login'
import Register from './pages/Register'

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/"> <WelcomePage /></Route>
        <Route path="/login" > <Login /></Route>
        <Route path="/register" > <Register /></Route>
        <Route path="/app" > <ResponsiveDrawer>
          <Switch>
            <Route path="/app/recipe-book/recipe" > <Recipe /></Route>
            <Route path="/app/recipe-book" > <RecipeBook /></Route>
            <Route path="/app/write-recipe"> <RecipeEditor /> </Route>
            <Route path="/app/edit-recipe"> <RecipeEditor /> </Route>
            <Route path="/app/friends"> <Friends /> </Route>
            <Route>
              <Redirect to="/app/recipe-book" />
            </Route>
          </Switch></ResponsiveDrawer>
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>

      </Switch>
    </BrowserRouter>
  );
}

export default App;
